import { Settings } from "react-slick";

export const sliderSettings: Settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  autoplay: true,
  dots: false,
  centerMode: false,
  centerPadding: "0px",
  speed: 500,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        dots: true,
      },
    },
  ],
};
