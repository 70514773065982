import React from "react";
import { Content } from "@prismicio/client";
import { PrismicImage, PrismicRichText } from "@prismicio/react";
import style from "../HomeProduct.module.scss";

export default function Card({
  slice,
}: {
  slice: Content.BlockSliderDocument;
}) {
  return (
    <>
      <div className={style.cards}>
        <div>
          <div>
            <div className={style.figure}>
              <PrismicImage
                field={slice.data.locationIcon}
                width={52}
                height={52}
              />
            </div>
            <div className={style.text}>
              <PrismicRichText field={slice.data.fabricationLocationText} />
            </div>
          </div>
          <div>
            <div className={style.figure}>
              <PrismicImage
                field={slice.data.nutriScoreIcon}
                width={71}
                height={38}
              />
            </div>
            <div className={style.text}>
              <PrismicRichText field={slice.data.nutriScoreLabel} />
            </div>
          </div>
        </div>
      </div>
      <div className={style.violetBox}>
        <strong>{slice.data.HealthyRecipesTitle} </strong>
        <div className={style.flex}>
          <em>{`${slice.data.HealthyRecipesPercentValue ?? ""}%`}</em>
          <span>{slice.data.HealthyRecipesText}</span>
        </div>
      </div>
    </>
  );
}
